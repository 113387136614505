<template>
    <div class="content-wrapper">
        <PageHeader :screenName="'Editar '+tituloNome" :linkItems="linkItems"/>

        <ConfirmModal 
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirecionarLista"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirecionarLista"
        />

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="doencaRules">
                    <b-form>
                        <b-row>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Nome técnico da doença*" 
                                label-for="nome-tecnico-doenca-input"
                                label-class="label_size_doenca">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="NomeTecnicoDoenca"
                                        rules="required"
                                    >
                                        <b-form-input 
                                            :state="errors.length > 0 || form.errorApi.nomeTecnico || form.errorApi.error ? false:null"
                                            id="nome-tecnico-doenca-input" 
                                            v-model="form.nomeTecnico"
                                            placeholder="Ex: Influenza"
                                            v-mask="('X'.repeat(250))"
                                        />
                                        <small class="text-muted">
                                            Nome conhecido no meio científico.
                                        </small>
                                        <br>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.nomeTecnico" class="text-danger">
                                            {{ form.errorApi.nomeTecnico }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Vacinas relacionadas" 
                                label-for="vacinas-relacionadas-div"
                                label-class="label_size_doenca"
                                v-if="vacinasSelecionadas != ''">
                                    <vue-perfect-scrollbar class="altura_max_vacinas_scrollbar" :settings="settings">
                                        <div id="vacinas-relacionadas-div" class="d-flex flex-wrap centralizar_responsivo">
                                            <div 
                                            v-for="vacinaSelecionada in vacinasSelecionadas" 
                                            :key="vacinaSelecionada.nome_tecnico"
                                            class="rounded vacinas"
                                            :class="vacinaSelecionada.class"
                                            v-b-tooltip.hover title="Para editar esse campo acesse a vacina relacionada a essa doença"
                                            >
                                                {{vacinaSelecionada.nome_tecnico}}
                                            </div>
                                        </div>
                                    </vue-perfect-scrollbar>       
                                </b-form-group>
                                <b-form-group label="Vacinas relacionadas"
                                label-for="vacinas-relacionadas-span"
                                label-class="label_size_doenca"
                                v-else>
                                    <span
                                        id="vacinas-relacionadas-span"
                                    >
                                        -
                                    </span>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Nome popular da doença" 
                                label-for="nome-popular-doenca-input"
                                label-class="label_size_doenca">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="NomePopularDoenca"
                                    >
                                        <b-form-input 
                                            :state="errors.length > 0 || form.errorApi.nomePopular ? false:null"
                                            id="nome-popular-doenca-input" 
                                            v-model="form.nomePopular"
                                            placeholder="Ex: Gripe"
                                            v-mask="('X'.repeat(250))"
                                        />
                                        <small class="text-muted">
                                            Nome conhecido popularmente.
                                        </small>
                                        <br>
                                        <small v-if="form.errorApi.nomePopular" class="text-danger">
                                            {{ form.errorApi.nomePopular }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="6" sm="12">
                                <b-form-group label="Descrição*" 
                                label-for="descricao-doenca-input"
                                label-class="label_size_doenca">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="DescricaoDoenca"
                                        rules="required"
                                    >
                                        <b-form-textarea 
                                            :state="errors.length > 0 || form.errorApi.descricao ? false:null"
                                            id="descricao-doenca-input" 
                                            rows="5"
                                            placeholder="Adicione uma descrição para esta doença"
                                            v-model="form.descricao"
                                            no-resize
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.descricao" class="text-danger">
                                            {{ form.errorApi.descricao }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>
            <section class="buttons">
                <b-button id="save-doenca" 
                    class="mr-2 mt-3 botao_responsivo" 
                    variant="custom-blue"
                    @click.prevent="validationForm(true)"
                    :disabled="saving"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar alterações</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-doenca" 
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                    class="mt-3"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea, VBTooltip } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import handleErrorsService from './Services/HandleErrors'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { getIdDoenca } from '@core/utils/store/getStore';
import { setIdDoenca } from '@core/utils/store/setStore';

export default {
    title: 'Editar doença',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, vSelect, ValidationProvider, ValidationObserver,
        PageHeader, ConfirmModal, SucessoModal, ErrorModal, VuePerfectScrollbar
    },

    directives: {
        'b-tooltip': VBTooltip
    },

    data() {
        return {
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Doenças',
                    routeName: 'doenca-list'
                },
                {
                    name: 'Editar',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            required,
            saving: false,
            form: {
                nomeTecnico: '',
                nomePopular: '',
                descricao: '',
                errorApi: {
                    nomeTecnico: '',
                    nomePopular: '',
                    descricao: '',
                    error: ''
                }
            },
            idDoencaEditar : null,
            tituloNome: '',
            vacinasSelecionadas: []
        }
    },

    mounted() {
        const hasModalError = this.$helpers.verifyIfCanOpenModalError(getIdDoenca(), this.$route.params.idDoenca);
        const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(getIdDoenca(), this.$route.params.idDoenca);
        
        if(hasModalError)
            return this.openModalError();
        
        if(hasIdEntityRouteParam) {
            setIdDoenca(this.$route.params.idDoenca); 
        }

        this.$http.get(this.$api.doencaWithParameter(getIdDoenca())).then(({ data }) => {
            this.preenchimentoFormulario(data);
        });
    },

    methods: {
        validationForm(redirectToList) {
            this.$refs.doencaRules.validate().then(success => {
                if (success) {
                    if(redirectToList){
                        this.saving = true;
                    }  
                    this.editDoenca(redirectToList);
                }
            })
        },

        getArrayNomesVacinas(dados) { 
            const arrayVacinas = dados.vacinas;
            for (let i = 0; i < arrayVacinas?.length; i++) {
                const objeto = 
                    {
                        nome_tecnico: arrayVacinas[i].nome_tecnico,
                        class: "vacinas"
                    }
                this.vacinasSelecionadas.push(objeto);
            }
        },

        editDoenca(redirectToList) {
            const parameters = {
                'nome_tecnico': this.form.nomeTecnico,
                'nome_popular': this.form.nomePopular,
                'descricao': this.form.descricao
            }

            this.$http.put(this.$api.doencaWithParameter(this.idDoencaEditar), parameters).then(() => {
                if(redirectToList) {
                    this.openModalSucesso();
                }

                this.saving = false;
                this.cleanForm();

            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosDoenca(data, this.form.errorApi);

                this.saving = false;
            })
        },

        cleanForm() {
            this.form.nomeTecnico = '';
            this.form.nomePopular = '';
            this.form.descricao = '';

            this.form.errorApi.nomeTecnico = '';
            this.form.errorApi.nomePopular = '';
            this.form.errorApi.descricao = '';
            this.form.errorApi.error = ''

            this.$refs.doencaRules.reset();
        },

        preenchimentoFormulario(dados){
            this.idDoencaEditar  = dados.id_doenca;
            this.form.nomeTecnico = dados.nome_tecnico;
            this.form.nomePopular = dados.nome_popular;
            this.form.descricao = dados.descricao;
            this.linkItems[1].name = 'Editar '+dados.nome_tecnico;
            this.tituloNome = dados.nome_tecnico;
            this.getArrayNomesVacinas(dados);
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        openModalSucesso() {
            this.modalSucesso.showModal = true;
        },

        openModalError() {
            this.modalError.showModal = true;
        },

        redirecionarLista() {
            this.$router.push({ name: 'doenca-list' });
        }
    }
}
</script>

<style scoped>
.vacinas{
    background-color: #2772C0;
    color: white;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
@media (max-width: 768px) { 
    .buttons{
        text-align: center;
    }
    .botao_responsivo{
        margin-left: 1.25rem;
    }
}
</style>